























import { Component, Prop, Watch } from "vue-property-decorator";
import { UseFields } from "piramis-base-components/src/components/Pi";
import { Statistics } from "../../logic/types";
import HeatmapChart from "piramis-base-components/src/components/Charts/Heatmap/heatmap.vue";
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { cloneDeep } from 'lodash'
import moment from "moment";
import isMobile from "@/assets/utils/isMobile";
import { DefaultChartData, Series } from 'piramis-base-components/src/components/Charts/logic/types'

@Component({
  components: {
    HeatmapChart,
    TableExportButtons
  }
})
export default class UsersRetention extends UseFields {
  @Prop() rawData!: Statistics

  @Prop() loaded!: boolean

  @Watch('loaded')
  loadedWatcher(value: boolean): void {
    if (!value) {
      this.usersRetention = null
    }
  }

  @Watch('currentPeriod')
  periodWatcher() {
    this.chartTemplate += 1
  }

  retentionTable: any = null

  chartTemplate = 0

  usersRetention: DefaultChartData<Series> | null = null

  options = {
    chart: {
      height: 500,
      xAxis: {
        enabled: true
      },
      yAxis: {
        enabled: true,
      },
      sparkline: true,
    },
    colors: [ 'rgb(51,153,255)' ],
    showInfo: true,
    zoom: false,
    stroke: {
      curve: 'smooth'
    },
    dataLabels: {
      fontSize: isMobile() ? '10px' : '8px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400
    },
    tooltip: {
      fontSize: '12px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400,
    }
  }

  getRetentionTable(): void {
    let days: { [key: string]: number } = {
      [`1 ${ this.$t('day') }`]: 0,
      [`2 ${ this.$t('day') }`]: 0,
      [`3 ${ this.$t('day') }`]: 0,
      [`4 ${ this.$t('day') }`]: 0,
      [`5 ${ this.$t('day') }`]: 0,
      [`6 ${ this.$t('day') }`]: 0,
      [`7 ${ this.$t('day') }`]: 0,
      [`8 ${ this.$t('day') }`]: 0,
      [`9 ${ this.$t('day') }`]: 0,
      [`10 ${ this.$t('day') }`]: 0,
      [`11 ${ this.$t('day') }`]: 0,
      [`12 ${ this.$t('day') }`]: 0,
      [`13 ${ this.$t('day') }`]: 0,
      [`14 ${ this.$t('day') }`]: 0,
      [`15 ${ this.$t('day') }`]: 0,
      [`16 ${ this.$t('day') }`]: 0,
      [`17 ${ this.$t('day') }`]: 0,
      [`18 ${ this.$t('day') }`]: 0,
      [`19 ${ this.$t('day') }`]: 0,
      [`20 ${ this.$t('day') }`]: 0,
      [`21 ${ this.$t('day') }`]: 0,
      [`22 ${ this.$t('day') }`]: 0,
      [`23 ${ this.$t('day') }`]: 0,
      [`24 ${ this.$t('day') }`]: 0,
      [`25 ${ this.$t('day') }`]: 0,
      [`26 ${ this.$t('day') }`]: 0,
      [`27 ${ this.$t('day') }`]: 0,
      [`28 ${ this.$t('day') }`]: 0,
      [`29 ${ this.$t('day') }`]: 0,
      [`30 ${ this.$t('day') }`]: 0,
      [`31 ${ this.$t('day') }`]: 0
    }

    const hourTable: Array<any> = this.rawData.users_retention.map(item => {
      return Object.assign({ [this.$t('statistics_table_date_title').toString()]: moment(item.time).format('YYYY-MM-DD') },cloneDeep(days))
    })

    this.rawData.users_retention.forEach(retentions => {
      const findItem = hourTable.find(item => item[this.$t('statistics_table_date_title').toString()] === moment(retentions.time).format('YYYY-MM-DD'))
      if (findItem) {
        retentions.user_days_from_first_message.forEach((retention, index) => {
          if (index !== 0) {
            findItem[`${ index } ${ this.$t('day') }`] = retention
          }
        })
      }
    })
    this.retentionTable = hourTable
  }

  mounted(): void {
    let days: Array<string> = this.rawData.users_retention.map(item => item.time).reverse()
    let retentions: Array<Array<number>> = this.rawData.users_retention.map(item => item.user_days_from_first_message).reverse()
    let users: Array<Array<number>> = new Array(31)

    retentions.forEach((retention, retIndex) => {
      retention.forEach((count, countIndex) => {
        if(countIndex !== 0) {
          if (users[countIndex - 1]) {
            users[countIndex - 1][retIndex] = count
          } else {
            users[countIndex - 1] = []
            users[countIndex - 1][retIndex] = count
          }
        }
      })
    })

    let usersRetentions: any = []
    users.forEach((item, index) => {
      usersRetentions.push({
        name: `${ index + 1 } ${ this.$t('day') }`,
        data: item
      })
    })

    this.usersRetention = {
      series: [ ...usersRetentions ],
      labels: days
    }

    this.options.zoom = days.length > 35
    this.getRetentionTable()
  }
}
