var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-perfect-scrollbar',{staticClass:"flex p-2",attrs:{"settings":{
    maxScrollbarLength: 60,
    swipeEasing: true,
    useBothWheelAxes: true,
  }}},_vm._l((Object.entries(_vm.GlobalRecords)),function(ref){
  var key = ref[0];
  var params = ref[1];
return _c('a-card',{key:key,staticClass:"record-card mr-2 last:mr-0 flex h-full"},[_c('div',{staticClass:"flex h-full"},[_c('div',{staticClass:"rounded-full flex items-center justify-center mr-4"},[_c('icon',{staticClass:"rounded-full",style:({backgroundColor: ("rgba(" + (params.color) + ",0.3)")}),attrs:{"icon-pack":"feather","size":"28px","icon":("icon-" + (params.icon)),"color":("rgb(" + (params.color) + ")")}})],1),_c('div',{staticClass:"w-full flex flex-col justify-between"},[_c('div',[_c('span',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.$t(params.title))+" ")])]),_c('div',{staticClass:"w-full text-right"},[_c('span',{staticClass:"w-full text-primary text-right font-semibold text-lg"},[_vm._v(" "+_vm._s(params.value)+" ")])])])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }