













































































































































import moment from "moment";
import PageTitle from '@/components/PageTitle.vue'
import SetPeriod from "@/views/chat/statistics/components/mainStatistics/setPeriod.vue";
import { Component } from "vue-property-decorator";
import Api from "@/includes/logic/Api";
import metrics from "./logic/metrics";

import MobileLandscapeTriggerLayout from "../../../components/MobileLandscapeTriggerLayout.vue";

import GlobalChartRecords
  from "@/views/chat/statistics/components/mainStatistics/globalChatRecords/globalChartRecords.vue";
import MixedUsersStatistic from "@/views/chat/statistics/components/mainStatistics/mixedUsersStatistic.vue";
import MsgUsersCount from "@/views/chat/statistics/components/mainStatistics/msgUsersCount.vue";
import UsersRetention from "@/views/chat/statistics/components/mainStatistics/usersRetention.vue";
import EngagementRate from "@/views/chat/statistics/components/mainStatistics/engagementRate.vue";
import UsersActivityHeatmap from "@/views/chat/statistics/components/mainStatistics/usersActivityHeatmap.vue";
import MsgCountHeatmap from "@/views/chat/statistics/components/mainStatistics/msgCountHeatmap.vue";
import NewUsers from "@/views/chat/statistics/components/mainStatistics/newUsers.vue";
import RetentionChartUsers from "@/views/chat/statistics/components/mainStatistics/retentionChartUsers.vue";
import RetentionChartMsg from "@/views/chat/statistics/components/mainStatistics/retentionChartMsg.vue";
import StatisticsHelpers from "@/views/chat/statistics/logic/statisticsHelpers";
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { errorNotification } from '@/includes/NotificationService'
import UsersActivityWrapper from '@/views/chat/statistics/components/mainStatistics/UsersActivityWrapper.vue'
import MsgActivityWrapper from '@/views/chat/statistics/components/mainStatistics/MsgActivityWrapper.vue'

@Component({
  components: {
    MobileLandscapeTriggerLayout,
    RetentionChartMsg,
    RetentionChartUsers,
    NewUsers,
    MsgCountHeatmap,
    UsersActivityHeatmap,
    EngagementRate,
    UsersRetention,
    MsgUsersCount,
    MixedUsersStatistic,
    GlobalChartRecords,
    PageTitle,
    SetPeriod,
    NotAvailableOptionsOverlay,
    UsersActivityWrapper,
    MsgActivityWrapper,
  }
})
export default class Statistics extends StatisticsHelpers {
  period = {
    from: moment().add(-1, 'months').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  }

  rawData: Statistics | null = null

  loaded = false
  componentLoader = false

  showMore = 0

  getPeriodStat(): void {
    const from = moment(this.period.from)
    const to = moment(this.period.to)

    const diff = to.diff(from, 'day')

    if (from.isSameOrBefore(to) && to.isBefore(moment())) {
      if (diff < 180) {
        this.loaded = true
        this.showMore = 0

        Api.getStat('tg', {
          to: this.period.to,
          from: this.period.from,
          chat_id: this.$store.state.chatState.chat.chat_id,
          metrics: metrics
        }).then(res => {
          this.rawData = res.data
          this.loaded = false
        })
      } else {
        errorNotification(this.$t('statistics_period_limit_is_exceeded').toString())
      }
    } else {
      errorNotification(this.$t('statistics_period_is_not_correct').toString())
    }
  }

  mounted(): void {
    this.$baseTemplate.saveButton.hide();
  }

  created(): void {
    this.loaded = true

    Api.getStat('tg', {
      to: this.period.to,
      from: this.period.from,
      chat_id: this.$store.state.chatState.chat.chat_id,
      metrics: metrics
    }).then(res => {
      this.rawData = res.data
      this.loaded = false
    })
  }

  destroyed() {
    this.$baseTemplate.saveButton.show();
  }
}
