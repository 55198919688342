





































import isMobile from "@/assets/utils/isMobile";
import { Statistics } from "../../logic/types";

import { UseFields } from "piramis-base-components/src/components/Pi";
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { DefaultChartData, Series } from 'piramis-base-components/src/components/Charts/logic/types'
import { ColumnOptions } from 'piramis-base-components/src/components/Charts/ColumnChart/type'
import NewColumnChart from 'piramis-base-components/src/components/NewCharts/components/NewColumnChart.vue'
import { ColumnChartOptions } from 'piramis-base-components/src/components/NewCharts/types/ColumnOptions'

import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { cloneDeep } from 'lodash'

@Component({
  components: {
    NewColumnChart,
    TableExportButtons
  }
})
export default class NewUsers extends UseFields {
  @Prop() rawData!: Statistics

  @Prop() period!: { from: string, to: string }

  @Prop() loaded!: boolean

  @Watch('loaded')
  loadedWatcher(value: boolean): void {
    if (!value) {
      this.users = null
    }
  }

  @Watch('currentPeriod')
  periodWatcher() {
    this.chartTemplate += 1
  }

  dayTable: any = null

  hourTable: any = null

  chartTemplate = 0

  currentPeriod = 0

  users: { days: DefaultChartData<Series>, hours: DefaultChartData<Series>, options: ColumnOptions } | null = null

  get dayDates(): Array<string> {
    let dates: Array<string> = []

    let from = moment(this.period.from)
    let to = moment(this.period.to).isSameOrBefore(moment()) ? moment(this.period.to) : moment(moment().format('YYYY-MM-DD'))

    while (from.isSameOrBefore(to)) {
      dates.push(from.format('YYYY-MM-DD'))
      from.add(1, 'days')
    }
    return dates
  }

  get hourDates(): Array<string> {
    let dates: Array<string> = []

    let from = moment(this.period.from)
    let to = moment(this.period.to).isSameOrBefore(moment()) ? moment(this.period.to) : moment(moment().format('YYYY-MM-DD'))

    while (from.isSameOrBefore(to)) {
      dates.push(from.format('YYYY-MM-DD HH:mm:ss'))
      from.add(1, 'hours')
    }
    return dates
  }

  getUsersPerDay(): { [key: string]: Array<number> } {
    let total: Array<number> = new Array(this.dayDates.length).fill(0)
    let newUsers: Array<number> = new Array(this.dayDates.length).fill(0)
    let repeated: Array<number> = new Array(this.dayDates.length).fill(0)
    let invited: Array<number> = new Array(this.dayDates.length).fill(0)

    this.rawData.users_new_day.forEach(day => {
      let dateIndex = this.dayDates.findIndex(item => item === moment(day.time).format('YYYY-MM-DD'))
      total[dateIndex] += day.total_users
      newUsers[dateIndex] += day.unique_first_users
      repeated[dateIndex] += day.unique_repeated_users
      invited[dateIndex] += day.total_invited_users
    })
    return {
      total,
      newUsers,
      repeated,
      invited
    }
  }

  getUsersPerHour(): { [key: string]: Array<number> } {
    let total: Array<number> = new Array(this.hourDates.length).fill(0)
    let newUsers: Array<number> = new Array(this.hourDates.length).fill(0)
    let repeated: Array<number> = new Array(this.hourDates.length).fill(0)
    let invited: Array<number> = new Array(this.hourDates.length).fill(0)

    this.rawData.users_new_hour.forEach(day => {
      let dateIndex = this.hourDates.findIndex(item => item === day.time)
      total[dateIndex] += day.total_users
      newUsers[dateIndex] += day.unique_first_users
      repeated[dateIndex] += day.unique_repeated_users
      invited[dateIndex] += day.total_invited_users
    })
    return {
      total,
      newUsers,
      repeated,
      invited,
    }
  }

  get columnOptions(): ColumnChartOptions{
    return {
      chart: {
        height: 400,
        zoom: true,
        margins: {
          right: 10,
        }
      },
      xAxis: {
        visible: true,
        ...this.currentPeriod === 0 ? {
          formatter: value => moment(value).format('MMM D')
        } : {
          formatter: value => moment(value).format('YYYY-MM-DD HH:mm')
        }
      },
      legend:{
        visible: true,
        showValue: false
      },
      yAxis: {
        visible: true,
      },
      colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-success), 1)', 'rgba(var(--a-warning), 1)', 'rgba(var(--a-danger), 1)' ],
      tooltip: {
        visible: true,
        showName: false,
        colorTip: true
      },
    }
  }

  mounted() {
    const usersPerDay = this.getUsersPerDay()
    const usersPerHour = this.getUsersPerHour()
    this.users = {
      days: {
        series: [
          {
            name: this.$t('statistics_total_new_users_title').toString(),
            data: usersPerDay.total
          },
          {
            name: this.$t('statistics_unique_first_users_title').toString(),
            data: usersPerDay.newUsers
          },
          {
            name: this.$t('statistics_unique_repeated_users_title').toString(),
            data: usersPerDay.repeated
          },
          {
            name: this.$t('statistics_unique_invited_users_title').toString(),
            data: usersPerDay.invited
          }
        ],
        labels: this.dayDates
      },
      hours: {
        series: [
          {
            name: this.$t('statistics_total_new_users_title').toString(),
            data: usersPerHour.total
          },
          {
            name: this.$t('statistics_unique_first_users_title').toString(),
            data: usersPerHour.newUsers
          },
          {
            name: this.$t('statistics_unique_repeated_users_title').toString(),
            data: usersPerHour.repeated
          },
          {
            name: this.$t('statistics_unique_invited_users_title').toString(),
            data: usersPerHour.invited
          }
        ],
        labels: this.hourDates,
      },
      options: {
        chart: {
          height: 500,
          xAxis: {
            enabled: true
          },
          yAxis: {
            enabled: true,
            hideLine: true
          },
          sparkline: true,
        },
        colors: [ 'rgb(51,153,255)','rgb(0, 227, 150)', 'rgb(254, 176, 25)', 'rgb(255, 69, 96)' ],
        zoom: this.hourDates.length > 35,
        dataLabels: {
          fontSize: isMobile() ? '10px' : '8px',
          fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
          fontWeight: 400
        },
      }
    }
    this.getDayTable()
    this.getHourTable()
  }

  getDayTable(): void {
    const fields = {
      [this.$t('statistics_total_new_users_title').toString()]: 0,
      [this.$t('statistics_unique_first_users_title').toString()]: 0,
      [this.$t('statistics_unique_repeated_users_title').toString()]: 0,
      [this.$t('statistics_unique_invited_users_title').toString()]: 0
    }

    const dates: Array<{[key: string]: string | number}> = this.dayDates.map(item => {
      return Object.assign({
        [this.$t('statistics_table_date_title').toString()]:item
      }, cloneDeep(fields))
    })

    this.rawData.users_new_day.forEach(day => {
      let findItem = dates.find(item => item[this.$t('statistics_table_date_title').toString()] === moment(day.time).format('YYYY-MM-DD'))
      if (findItem) {
        findItem[this.$t('statistics_total_new_users_title').toString()] = day.total_users
        findItem[this.$t('statistics_unique_first_users_title').toString()] = day.unique_first_users
        findItem[this.$t('statistics_unique_repeated_users_title').toString()] = day.unique_repeated_users
        findItem[this.$t('statistics_unique_invited_users_title').toString()] = day.total_invited_users
      }
    })
    this.dayTable = dates
  }

  getHourTable(): void {
    const fields = {
      [this.$t('statistics_total_new_users_title').toString()]: 0,
      [this.$t('statistics_unique_first_users_title').toString()]: 0,
      [this.$t('statistics_unique_repeated_users_title').toString()]: 0,
      [this.$t('statistics_unique_invited_users_title').toString()]: 0
    }

    const dates: Array<{[key: string]: string | number}> = this.hourDates.map(item => {
      return Object.assign({
        [this.$t('statistics_table_date_title').toString()]:item
      }, cloneDeep(fields))
    })

    this.rawData.users_new_hour.forEach(day => {
      let findItem = dates.find(item => item[this.$t('statistics_table_date_title').toString()] === day.time)
      if (findItem) {
        findItem[this.$t('statistics_total_new_users_title').toString()] = day.total_users
        findItem[this.$t('statistics_unique_first_users_title').toString()] = day.unique_first_users
        findItem[this.$t('statistics_unique_repeated_users_title').toString()] = day.unique_repeated_users
        findItem[this.$t('statistics_unique_invited_users_title').toString()] = day.total_invited_users
      }
    })
    this.hourTable = dates
  }

}
