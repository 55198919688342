




































import { Component, Prop, Watch } from "vue-property-decorator";
import { UseFields } from "piramis-base-components/src/components/Pi";
import HeatmapChart from 'piramis-base-components/src/components/Charts/Heatmap/heatmap.vue'
import { Statistics } from "@/views/chat/statistics/logic/types";
import moment from 'moment'
import { WeekDaysLabels } from "../../logic/weekDays";
import { cloneDeep } from 'lodash'
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import isMobile from "@/assets/utils/isMobile";
import { DefaultChartData, Series } from 'piramis-base-components/src/components/Charts/logic/types'

@Component({
  components: {
    HeatmapChart,
    TableExportButtons
  }
})
export default class UsersActivityHeatmap extends UseFields {
  @Prop() rawData!: Statistics

  @Prop() period!: { from: string, to: string }

  @Prop() loaded!: boolean

  @Watch('loaded')
  loadedWatcher(value: boolean): void {
    if (!value) {
      this.activityUsers = null
    }
  }

  @Watch('currentPeriod')
  periodWatcher() {
    this.chartTemplate += 1
  }

  dayTable: any = null

  hourTable: any = null

  chartTemplate = 0

  currentPeriod = 0

  activityUsers: {hours: DefaultChartData<Series>, days: DefaultChartData<Series> } | null = null

  options = {
    chart: {
      height: 500,
      xAxis: {
        enabled: true
      },
      yAxis: {
        enabled: true,
      },
      sparkline: true,
    },
    colors: [ 'rgb(51,153,255)' ],
    showInfo: true,
    zoom: this.rawData.msg_count_day.map(item => moment(item.time).format('YYYY-MM-DD')).length > 35,
    stroke: {
      curve: 'smooth'
    },
    dataLabels: {
      fontSize: isMobile() ? '10px' : '8px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400
    },
    tooltip: {
      fontSize: '12px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400,
    }
  }

  get datesByDay(): Array<string> {
    let dates: Array<string> = []

    let from = moment(this.period.from)
    let to = moment(this.period.to).isSameOrBefore(moment()) ? moment(this.period.to) : moment(moment().format('YYYY-MM-DD'))

    while (from.isBefore(to)) {
      dates.push(from.format('YYYY-MM-DD'))
      from.add(1, 'days')
    }

    return dates
  }

  get datesByWeek(): Array<{ from: string, to: string }> {
    let dates: Array<{ from: string, to: string }> = []

    let from = moment(this.period.from)
    let to = moment(this.period.to).isSameOrBefore(moment()) ? moment(this.period.to) : moment(moment().format('YYYY-MM-DD'))

    while (from.isBefore(to)) {
      dates.push({ from: from.format('YYYY-MM-DD'), to: from.add(6, 'days').format('YYYY-MM-DD') })
      from.add(1, 'days').format('YYYY-MM-DD')
    }

    return dates
  }

  getUsersFromWeeks(): Array<{ name: string, data: Array<number> }> {
    let weekDays: Array<Array<number>> = []

    this.rawData.msg_count_day.forEach(msg => {
      const dateIndex = this.datesByWeek.findIndex((period) =>
        moment(msg.time).isSameOrAfter(period.from)
        && moment(msg.time).isSameOrBefore(period.to))

      const weekDay = moment(msg.time).day()

      if (weekDays[weekDay]) {
        weekDays[weekDay][dateIndex] += msg.users_count
      } else {
        weekDays[weekDay] = new Array(this.datesByWeek.length).fill(0)
        weekDays[weekDay][dateIndex] += msg.users_count
      }
    })

    let users: Array<{ name: string, data: Array<number> }> = []
    weekDays.push(weekDays.shift() as Array<number>)
    weekDays.forEach((week, index) => {
      users.push({
        name: this.$t(`week_day_picker_${ WeekDaysLabels[index].toLowerCase() }`).toString(),
        data: week
      })
    })
    return users.reverse()
  }

  getUsersFromDays(): Array<{ name: string, data: Array<number> }> {
    let times: Array<Array<number>> = []

    let from = moment(this.period.from)
    let to = moment(this.period.to).isSameOrBefore(moment()) ? moment(this.period.to) : moment(moment().format('YYYY-MM-DD'))
    let daysCount = to.diff(from, 'days')

    this.rawData.msg_count_hour.forEach(msg => {
      const dateIndex = this.datesByDay.indexOf(moment(msg.time).format('YYYY-MM-DD'))
      const time = Number(moment(msg.time).format('HH'))
      if (times[time]) {
        times[time][dateIndex] += msg.users_count
      } else {
        times[time] = new Array(daysCount).fill(0)
      }
    })

    let users: Array<{ name: string, data: Array<number> }> = []
    times.forEach((time, index) => {
      users.unshift({
        name: String(index).length > 1 ? `${ index }:00` : `0${ index }:00`,
        data: time
      })
    })
    return users
  }

  mounted(): void {
    moment.locale(this.$i18n.locale)
    this.activityUsers = {
      hours: {
        series: [ ...this.getUsersFromDays() ],
        labels: this.datesByDay.map(item => moment(item).format('YYYY-MM-DD'))
      },
      days: {
        series: [ ...this.getUsersFromWeeks() ],
        labels: this.datesByWeek.map(item => `${ moment(item.from).format('YYYY-MM-DD') } - ${ moment(item.to).format('YYYY-MM-DD') }`),
      }
    }
    this.getDayTable()
    this.getWeekTable()
  }

  getDayTable(): void {
    let times: { [key: string]: number } = {
      '00:00': 0,
      '01:00': 0,
      '02:00': 0,
      '03:00': 0,
      '04:00': 0,
      '05:00': 0,
      '06:00': 0,
      '07:00': 0,
      '08:00': 0,
      '09:00': 0,
      '10:00': 0,
      '11:00': 0,
      '12:00': 0,
      '13:00': 0,
      '14:00': 0,
      '15:00': 0,
      '16:00': 0,
      '17:00': 0,
      '18:00': 0,
      '19:00': 0,
      '20:00': 0,
      '21:00': 0,
      '22:00': 0,
      '23:00': 0
    }

    const hourTable: Array<any> = []
    this.rawData.msg_count_hour.forEach(msg => {
      let findItem = hourTable.find(item => item[this.$t('statistics_table_date_title').toString()] === moment(msg.time).format('YYYY-MM-DD'))
      if (findItem) {
        findItem[moment(msg.time).format('HH:mm')] = msg.users_count
      } else {
        let copyTimes = cloneDeep(times)
        copyTimes[moment(msg.time).format('HH:mm')] = msg.users_count
        hourTable.push(Object.assign({ [this.$t('statistics_table_date_title').toString()]: moment(msg.time).format('YYYY-MM-DD') }, cloneDeep(copyTimes)))
      }
    })
    this.hourTable = hourTable
  }

  getWeekTable(): void {
    let week: { [key: string]: number } = {
      [this.$t('monday').toString()]: 0,
      [this.$t('tuesday').toString()]: 0,
      [this.$t('wednesday').toString()]: 0,
      [this.$t('thursday').toString()]: 0,
      [this.$t('friday').toString()]: 0,
      [this.$t('saturday').toString()]: 0,
      [this.$t('sunday').toString()]: 0
    }

    const times: Array<{[key: string]: string | number}> = this.datesByWeek.map(item => `${ moment(item.from).format('YYYY-MM-DD') } - ${ moment(item.to).format('YYYY-MM-DD') }`).map(item => {
      return Object.assign({
        [this.$t('statistics_table_date_title').toString()]: item
      }, week)
    })

    const weekDays = Object.keys(week)

    this.rawData.msg_count_day.forEach(msg => {
      const dateIndex = this.datesByWeek.findIndex((period) =>
        moment(msg.time).isSameOrAfter(period.from)
        && moment(msg.time).isSameOrBefore(period.to))

      if (this.datesByWeek[dateIndex]) {
        const weekDay = weekDays[moment(msg.time).weekday()]
        const period = `${ this.datesByWeek[dateIndex].from } - ${ this.datesByWeek[dateIndex].to }`
        const findWeek = times.find((item) => item[this.$t('statistics_table_date_title').toString()] === period)

        if (findWeek) {
          findWeek[weekDay] = msg.users_count
        } else {
          times.push(Object.assign({ [this.$t('statistics_table_date_title').toString()]: `${ this.datesByWeek[dateIndex].from } ${ this.datesByWeek[dateIndex].to }` }, cloneDeep(week)))
        }
      }

    })
    this.dayTable = times
  }
}
