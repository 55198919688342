import { render, staticRenderFns } from "./msgCountHeatmap.vue?vue&type=template&id=67b0622f&scoped=true&"
import script from "./msgCountHeatmap.vue?vue&type=script&lang=ts&"
export * from "./msgCountHeatmap.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b0622f",
  null
  
)

export default component.exports