










































import isMobile from "@/assets/utils/isMobile";
import { Statistics } from "@/views/chat/statistics/logic/types";

import { UseFields } from "piramis-base-components/src/components/Pi";
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { LineChartData } from 'piramis-base-components/src/components/Charts/LineChart/type'
import NewLineChart from 'piramis-base-components/src/components/NewCharts/components/NewLineChart.vue'
import { LineChartOptions as LCO } from 'piramis-base-components/src/components/NewCharts/types/LineOptions'

import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

@Component({
  components: {
    NewLineChart,
    TableExportButtons
  }
})
export default class EngagementRate extends UseFields{
  @Prop() rawData!: Statistics

  @Prop() loaded!: boolean

  @Watch('loaded')
  loadedWatcher(value: boolean): void {
    if (!value) {
      this.engagementRate = null
    }
  }

  @Watch('currentPeriod')
  periodWatcher() {
    this.chartTemplate += 1
  }

  monthTable: any = null
  weekTable: any = null

  chartTemplate = 0

  currentPeriod = 0

  engagementRate: { month: LineChartData, week: LineChartData } | null = null

  options = {
    chart: {
      height: 500,
      xAxis: {
        enabled: true
      },
      yAxis: {
        enabled: true,
        hideLine: true,
        max: 100
      },
      sparkline: true,
    },
    colors: [ '#008FFB', '#a2d0fb', '#00a71f', '#b7f4ca' ],
    showInfo: true,
    zoom: this.rawData.msg_count_day.map(item => moment(item.time).format('YYYY-MM-DD')).length > 35,
    stroke: {
      curve: 'smooth'
    },
    dataLabels: {
      fontSize: isMobile() ? '10px' : '8px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400
    },
    tooltip: {
      fontSize: '12px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400,
      postFix: '%'
    }
  }

  lineOptions:LCO = {
    chart: {
      height: 500,
      zoom: false,
      margins: {
        right: 10
      }
    },
    xAxis: {
      visible: true,
      formatter: value =>  moment(value).format("MMM D"),
    },
    yAxis: {
      max: 100,
      visible: true,
    },
    legend: {
      formatter: value => value + "%",
      visible: true,
    },
    tooltip: {
      visible: false
    },
    colors: [ "rgba(var(--a-primary), 1)" ],
  }

  getMonthTable(): void {
    this.monthTable = this.rawData.engagement_rate_month.map(item => {
      let obj: {[key: string]: string} = {}

      if (item.engagement_rate_users !== undefined) {
        obj[this.$t('user_involvement_chart_title').toString()] = `${ item.engagement_rate_users.toFixed(2) }%`
      } else {
        obj[this.$t('user_involvement_chart_title').toString()] = '-'
      }

      return obj
    })
  }

  getWeekTable(): void {
    this.weekTable = this.rawData.engagement_rate_week.map(item => {
      let obj: {[key: string]: string} = {}

      if (item.engagement_rate_users !== undefined) {
        obj[this.$t('user_involvement_chart_title').toString()] = `${ item.engagement_rate_users.toFixed(2) }%`
      } else {
        obj[this.$t('user_involvement_chart_title').toString()] = '-'
      }

      return obj
    })
  }

  mounted(): void {
    let weekData = this.rawData.engagement_rate_week.map(item => {
      if (item.engagement_rate_users !== undefined) {
        return Number(item.engagement_rate_users.toFixed(2))
      }

      return null
    })
    let monthData = this.rawData.engagement_rate_month.map(item => {
      if (item.engagement_rate_users !== undefined) {
        return Number(item.engagement_rate_users.toFixed(2))
      }

      return null
    })
    this.engagementRate = {
      week: {
        series: [
          {
            name: this.$t('user_involvement_chart_title').toString(),
            data: weekData
          }
        ],
        labels: this.rawData.engagement_rate_week.map(item => moment(item.time).format('YYYY-MM-DD'))
      },
      month: {
        series: [
          {
            name: this.$t('user_involvement_chart_title').toString(),
            data: monthData
          }
        ],
        labels: this.rawData.engagement_rate_month.map(item => moment(item.time).format('YYYY-MM-DD'))
      }
    }
    this.getMonthTable()
    this.getWeekTable()
  }
}
