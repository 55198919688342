























import { Component, Prop, Watch } from "vue-property-decorator";
import { UseFields } from "piramis-base-components/src/components/Pi";
import { Statistics } from "../../logic/types";
import MultiChart from "piramis-base-components/src/components/Charts/MultiChart/MultiChart.vue";
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import isMobile from "@/assets/utils/isMobile";
import { MultiChartData } from 'piramis-base-components/src/components/Charts/MultiChart/type'

@Component({
  components: {
    MultiChart,
    TableExportButtons
  }
})
export default class RetentionChartUsers extends UseFields {
  @Prop() rawData!: Statistics

  @Prop() loaded!: boolean

  @Watch('loaded')
  loadedWatcher(value: boolean): void {
    if (!value) {
      this.users = null
    }
  }

  @Watch('currentPeriod')
  periodWatcher() {
    this.chartTemplate += 1
  }

  usersTable: any = null

  chartTemplate = 0

  users: MultiChartData | null = null

  options = {
    chart: {
      height: 500,
      xAxis: {
        enabled: true
      },
      yAxis: {
        enabled: true,
        hideLine: true
      },
      sparkline: true,
    },
    colors: [ '#3399ff', '#fdcf2b', '#ff861d', '#ff5767', '#fb9f00' ],
    showInfo: true,
    zoom: false,
    stroke: {
      curve: 'smooth',
      width: 2
    },
    dataLabels: {
      fontSize: isMobile() ? '10px' : '8px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400
    },
    tooltip: {
      fontSize: '12px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400,
    }
  }

  mounted(): void {
    let usersCount = this.rawData.users_retention.map(item => item.users_count).reverse()
    let regularUsersCount = this.rawData.users_retention.map(item => item.user_days_in_chat_more).reverse()
    let conversion = this.rawData.users_retention.map(item => item.user_days_in_chat[1]).reverse()
    let labels = this.rawData.users_retention.map(item => item.time).reverse()
    let otherUsers: Array<number> = []

    usersCount.forEach((count, index) => {
      otherUsers.push(usersCount[index] - regularUsersCount[index] - conversion[index])
    })

    this.users = {
      series: [
        {
          name: this.$t('statistics_users_retention_users_count').toString(),
          data: usersCount,
          type: 'line'
        },
        {
          name: this.$t('statistics_users_retention_user_days_in_chat_more').toString(),
          data: regularUsersCount,
          type: 'column'
        },
        {
          name: this.$t('statistics_users_retention_conversion').toString(),
          data: conversion,
          type: 'column'
        },
        {
          name: this.$t('statistics_messages_other_users').toString(),
          data: otherUsers,
          type: 'column'
        }
      ],
      labels: labels
    }
    this.getUsersTable()
  }

  getUsersTable(): void {
    this.usersTable = this.rawData.users_retention.map(item => {
      return {
        [this.$t('statistics_table_date_title').toString()]: item.time,
        [this.$t('statistics_users_retention_users_count').toString()]: item.users_count,
        [this.$t('statistics_users_retention_user_days_in_chat_more').toString()]: item.user_days_in_chat_more,
        [this.$t('statistics_users_retention_conversion').toString()]: item.user_days_in_chat[1],
        [this.$t('statistics_messages_other_users').toString()]: item.users_count - item.user_days_in_chat_more - item.user_days_in_chat[1]
      }
    }).reverse()
  }

  // getOptions(labels: Array<string>): any {
  //   return {
  //     title: {
  //       text: this.$t('statistics_users_retention_chart_messages_title').toString()
  //     },
  //     chart: {
  //       animations: {
  //         enabled: false
  //       },
  //       defaultLocale: this.$i18n.locale,
  //       locales: ApexLocales,
  //       toolbar: ApexToolbar
  //     },
  //     colors: [
  //       '#008FFB',
  //       '#fdcf2b',
  //       '#ff861d',
  //       '#ff5767'
  //     ],
  //     dataLabels: {
  //       enabled: false
  //     },
  //     stroke: {
  //       width: [7, 0, 0, 0],
  //       curve: 'smooth',
  //       dashArray: [0, 0, 0, 0]
  //     },
  //     legend: {
  //       position: 'top',
  //       horizontalAlign: 'left'
  //     },
  //     xaxis: {
  //       type: 'datetime',
  //       categories: [...labels],
  //       convertedCatToNumeric: false
  //     },
  //     responsive: [
  //       {
  //         breakpoint: 667,
  //         options: {
  //           chart: {
  //             height: 300
  //           },
  //           legend: {
  //             position: 'bottom'
  //           }
  //         }
  //       }
  //     ]
  //   }
  // }
}
