






















import { Component, Prop, Watch } from "vue-property-decorator";
import { UseFields } from "piramis-base-components/src/components/Pi";
import { Statistics } from "../../logic/types";
import MultiChart from "piramis-base-components/src/components/Charts/MultiChart/MultiChart.vue";
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import moment from 'moment'
import isMobile from "@/assets/utils/isMobile";
import { MultiChartData } from 'piramis-base-components/src/components/Charts/MultiChart/type'

@Component({
  components: {
    MultiChart,
    TableExportButtons
  }
})
export default class RetentionChartMsg extends UseFields {
  @Prop() rawData!: Statistics

  @Prop() loaded!: boolean

  @Watch('loaded')
  loadedWatcher(value: boolean): void {
    if (!value) {
      this.msg = null
    }
  }

  msgTable: any = null

  msg: MultiChartData | null = null

  options = {
    chart: {
      height: 500,
      xAxis: {
        enabled: true
      },
      yAxis: {
        enabled: true,
        hideLine: true
      },
      sparkline: true,
    },colors: [ '#00a71f', '#fdcf2b', '#ff861d', '#ff5767' ],
    showInfo: true,
    zoom: false,
    stroke: {
      curve: 'smooth',
      width: 2
    },
    dataLabels: {
      fontSize: isMobile() ? '10px' : '8px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400
    },
    tooltip: {
      fontSize: '12px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400,
    }
  }

  getMsgTable(): void {
    this.msgTable = this.rawData.messages_retention.map(item => {
      return {
        [this.$t('statistics_table_date_title').toString()]: moment(item.time).format('YYYY-MM-DD'),
        [this.$t('statistics_users_retention_users_count').toString()]: item.msg_count,
        [this.$t('statistics_users_retention_user_days_in_chat_more').toString()]: item.msg_days_in_chat_more,
        [this.$t('statistics_users_retention_conversion').toString()]: item.msg_days_in_chat[1],
        [this.$t('statistics_messages_other_users').toString()]: item.msg_count - item.msg_days_in_chat_more - item.msg_days_in_chat[1]
      }
    }).reverse()
  }

  mounted(): void {
    let msgCount = this.rawData.messages_retention.map(item => item.msg_count).reverse()
    let regularUsersMsgCount = this.rawData.messages_retention.map(item => item.msg_days_in_chat_more).reverse()
    let conversion = this.rawData.messages_retention.map(item => item.msg_days_in_chat[1]).reverse()
    let labels = this.rawData.messages_retention.map(item => item.time).reverse()
    let otherUsersMsg: Array<number> = []

    msgCount.forEach((count, index) => {
      let other = msgCount[index] - regularUsersMsgCount[index] - conversion[index]
      otherUsersMsg.push(other < 0 ? 0 : other)
    })

    this.msg = {
      series: [
        {
          name: this.$t('statistics_users_retention_users_count').toString(),
          data: msgCount,
          type: 'line'
        },
        {
          name: this.$t('statistics_users_retention_user_days_in_chat_more').toString(),
          data: regularUsersMsgCount,
          type: 'column'
        },
        {
          name: this.$t('statistics_users_retention_conversion').toString(),
          data: conversion,
          type: 'column'
        },
        {
          name: this.$t('statistics_messages_other_users').toString(),
          data: otherUsersMsg,
          type: 'column'
        }
      ],
      labels,
    }
    this.getMsgTable()
  }
}
