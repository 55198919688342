















































import { Statistics, StatisticsPeriod } from '@/views/chat/statistics/logic/types'
import UsersActivityBars from "@/views/chat/statistics/components/mainStatistics/usersActivityBars.vue";

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { sum } from 'lodash'

@Component({
  components: {
    UsersActivityBars,
  },
  data() {
    return {
      StatisticsPeriod
    }
  }
})
export default class UserActivityWrapper extends Vue {
  @Prop({ default: null }) rawData!: Statistics

  get dau() {
    return (
      this.rawData
      && this.rawData.msg_count_day
      && this.rawData.msg_count_day.length
      && Math.floor(sum(this.rawData.msg_count_day.map(item => item.users_count)) / this.rawData.msg_count_day.length)
    ) || 0

  }

  get wau() {
    return (
      this.rawData
      && this.rawData.msg_count_week
      && this.rawData.msg_count_week.length
      && Math.floor(sum(this.rawData.msg_count_week.map(item => item.users_count)) / this.rawData.msg_count_week.length)
    ) || 0
  }

}
