






































import isMobile from "@/assets/utils/isMobile";
import { Statistics } from "@/views/chat/statistics/logic/types";

import { UseFields } from "piramis-base-components/src/components/Pi";
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { LineChartData, LineChartOptions } from 'piramis-base-components/src/components/Charts/LineChart/type'
import NewLineChart from 'piramis-base-components/src/components/NewCharts/components/NewLineChart.vue'
import { LineChartOptions as LCO } from 'piramis-base-components/src/components/NewCharts/types/LineOptions'

import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment"

@Component({
  components: {
    NewLineChart,
    TableExportButtons
  }
})
export default class MsgUsersCount extends UseFields {
  @Prop() rawData!: Statistics

  @Prop() loaded!: boolean

  @Watch('currentPeriod')
  periodWatcher() {
    this.chartTemplate += 1
  }

  @Watch('loaded')
  loadedWatcher(value: boolean): void {
    if (!value) {
      this.msgUsersCount = null
    }
  }

  dayTable: any = null
  weekTable: any = null

  chartTemplate = 0

  series = []

  options: LineChartOptions = {
    chart: {
      height: 500,
      xAxis: {
        enabled: true
      },
      yAxis: {
        enabled: true,
        hideLine: true
      },
    },
    colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-success), 1)', ],
    showInfo: true,
    zoom: this.rawData.msg_count_day.map(item => moment(item.time).format('YYYY-MM-DD')).length > 35,
    stroke: {
      curve: 'smooth'
    },
    dataLabels: {
      fontSize: isMobile() ? '10px' : '8px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400
    },
    chartInfo: {
      fontSize: '12px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400,
    }
  }

  lineOptions:LCO = {
    chart: {
      height: 500,
      zoom: false,
      margins: {
        right: 10
      }
    },
    xAxis: {
      visible: true,
      formatter: value => moment(value).format("MMM D"),
    },
    yAxis: {
      visible: true,
    },
    legend: {
      visible: true,
    },
    tooltip: {
      visible: false
    },
    colors: [ 'rgba(var(--a-success), 1)', "rgba(var(--a-primary), 1)" ],
  }

  currentPeriod = 0

  msgUsersCount: { month: LineChartData, week: LineChartData } | null = null

  getDayTable(): void {
    const dayTable: Array<{[key: string]: string | number}> = []
    this.rawData.msg_count_day.forEach((item, index) => {
      let obj: {[key: string]: string | number} = {}
      obj[this.$t('users_statistics_count_active').toString()] = item.users_count
      obj[this.$t('statistics_users_count_title').toString()] = item.msg_count
      if (index === 0) {
        obj[this.$t('statistics_users_count_sma_title').toString()] = '-'
        obj[this.$t('statistics_msg_count_sma_title').toString()] = '-'
      } else if (index === 1) {
        obj[this.$t('statistics_users_count_sma_title').toString()] = item.msg_count
        obj[this.$t('statistics_msg_count_sma_title').toString()] = item.users_count
      } else {
        const smaUser = (this.rawData.msg_count_day[index - 1].users_count / 100 * 75) + item.users_count / 100 * 25
        const smaMsg = (this.rawData.msg_count_day[index - 1].msg_count / 100 * 75) + item.msg_count / 100 * 25
        obj[this.$t('statistics_users_count_sma_title').toString()] = Math.round(smaMsg)
        obj[this.$t('statistics_msg_count_sma_title').toString()] = Math.round(smaUser)
      }
      dayTable.push(obj)
    })

    this.dayTable = dayTable
  }

  getWeekTable(): void {
    const weekTable: Array<{[key: string]: number | string}> = []
    this.rawData.msg_count_week.forEach((item, index) => {
      let obj: {[key: string]: number | string} = {}
      obj[this.$t('users_statistics_count_active').toString()] = item.users_count
      obj[this.$t('statistics_users_count_title').toString()] = item.msg_count
      if (index === 0) {
        obj[this.$t('statistics_users_count_sma_title').toString()] = '-'
        obj[this.$t('statistics_msg_count_sma_title').toString()] = '-'
      } else if (index === 1) {
        obj[this.$t('statistics_users_count_sma_title').toString()] = item.msg_count
        obj[this.$t('statistics_msg_count_sma_title').toString()] = item.users_count
      } else {
        const smaUser = (this.rawData.msg_count_day[index - 1].users_count / 100 * 75) + item.users_count / 100 * 25
        const smaMsg = (this.rawData.msg_count_day[index - 1].msg_count / 100 * 75) + item.msg_count / 100 * 25
        obj[this.$t('statistics_users_count_sma_title').toString()] = Math.round(smaMsg)
        obj[this.$t('statistics_msg_count_sma_title').toString()] = Math.round(smaUser)
      }
      weekTable.push(obj)
    })
    this.weekTable = weekTable
  }

  mounted() {
    let users_day = this.rawData.msg_count_day.map(item => item.users_count).reverse()
    let avg_users_day: Array<number | null> = []

    let msg_day = this.rawData.msg_count_day.map(item => item.msg_count).reverse()
    let avg_msg_day: Array<number | null> = []

    let users_week = this.rawData.msg_count_week.map(item => item.users_count).reverse()
    let avg_users_week: Array<number | null> = []
    let msg_week = this.rawData.msg_count_week.map(item => item.msg_count).reverse()
    let avg_msg_week: Array<number | null> = []

    this.msgUsersCount = {
      month: {
        series: [
          {
            name: this.$t('statistics_users_count_title').toString(),
            data: users_day
          },
          // {
          //   name: this.$t('statistics_users_count_sma_title').toString(),
          //   data: this.getAvgArr(users_day, avg_users_day),
          //   strokeType: 'broken-line'
          // },
          {
            name: this.$t('users_statistics_count_active').toString(),
            data: msg_day
          },
          // {
          //   name: this.$t('statistics_msg_count_sma_title').toString(),
          //   data: this.getAvgArr(msg_day, avg_msg_day),
          //   strokeType: 'broken-line'
          // },
        ],
        labels: this.rawData.msg_count_day.map(item => moment(item.time).format('YYYY-MM-DD')).reverse()
      },
      week: {
        series: [
          {
            name: this.$t('statistics_users_count_title').toString(),
            data: users_week
          },
          // {
          //   name: this.$t('statistics_users_count_sma_title').toString(),
          //   data: this.getAvgArr(users_week, avg_users_week),
          //   strokeType: 'broken-line'
          // },
          {
            name: this.$t('users_statistics_count_active').toString(),
            data: msg_week
          },
          // {
          //   name: this.$t('statistics_msg_count_sma_title').toString(),
          //   data: this.getAvgArr(msg_week, avg_msg_week),
          //   strokeType: 'broken-line'
          // },
        ],
        labels: this.rawData.msg_count_week.map(item => moment(item.time).format('YYYY-MM-DD')).reverse()
      }
    }
    this.getDayTable()
    this.getWeekTable()
  }

  getAvgArr(arr1: Array<number | null>, arr2: Array<number | null>): Array<number | null> {
    arr1.forEach((item, index) => {
      if (index === 0) {
        arr2.push(null)
      } else if (index === 1) {
        arr2.push(item)
      } else {
        arr2.push(Math.round((arr2[index - 1]! / 100 * 75) + item! / 100 * 25))
      }
    })
    return arr2
  }
}
