











































import isMobile from "@/assets/utils/isMobile";
import { Statistics } from "@/views/chat/statistics/logic/types";

import { UseFields } from "piramis-base-components/src/components/Pi";
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { DefaultChartData, Series } from 'piramis-base-components/src/components/Charts/logic/types'
import NewColumnChart from 'piramis-base-components/src/components/NewCharts/components/NewColumnChart.vue'
import { ColumnChartOptions } from 'piramis-base-components/src/components/NewCharts/types/ColumnOptions'

import { Component, Prop } from "vue-property-decorator";
import moment from "moment";

@Component({
  components: {
    TableExportButtons,
    NewColumnChart
  }
})
export default class MsgActivityStacked extends UseFields {
  @Prop() rawData!: Statistics

  @Prop() stat!:string

  // @Watch('currentPeriod')
  // periodWatcher() {
  //   this.chartTemplate += 1
  // }

  // dayTable: any = null
  //
  // hourTable: any = null

  // chartTemplate = 0

  // currentPeriod = 0

  loaded = false

  activityMsg: {days: DefaultChartData<Series>, hours: DefaultChartData<Series>} | null = null

  options = {
    chart: {
      height: 100,
      xAxis: {
        enabled: false
      },
      yAxis: {
        enabled: false,
      },
      sparkline: true,
    },
    colors: [ 'rgba(var(--a-primary), 1)' ],
    showInfo: true,
    zoom: false,
    stroke: {
      curve: 'smooth'
    },
    dataLabels: {
      fontSize: isMobile() ? '10px' : '8px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400
    },
    tooltip: {
      fontSize: '12px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400,
    }
  }

  get columnOptions(): ColumnChartOptions{
    return {
      chart: {
        height: 100,
        zoom: false,
        margins: {
          right: 0,
          left: 0,
          top: 0,
          bottom: 5
        }
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      colors: [ 'rgba(var(--a-primary), 1)' ],
      tooltip: {
        visible: true,
        showName: false,
        colorTip: false
      },
      ...this.stat === 'day' ? {
        highlight:{
          keys: [ ...this.activityMsg!.days.labels.slice(-1), ...this.activityMsg!.days.labels.slice(-2) ],
          color: 'rgba(var(--a-danger), 1)'
        }
      } : {}
    }
  }

  mounted(): void {
    let days: { [key: string]: number } = {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    }

    let hours: { [key: string]: number} = {
      '00:00': 0,
      '01:00': 0,
      '02:00': 0,
      '03:00': 0,
      '04:00': 0,
      '05:00': 0,
      '06:00': 0,
      '07:00': 0,
      '08:00': 0,
      '09:00': 0,
      '10:00': 0,
      '11:00': 0,
      '12:00': 0,
      '13:00': 0,
      '14:00': 0,
      '15:00': 0,
      '16:00': 0,
      '17:00': 0,
      '18:00': 0,
      '19:00': 0,
      '20:00': 0,
      '21:00': 0,
      '22:00': 0,
      '23:00': 0
    }
    moment.locale('en')

    this.rawData.msg_count_day.forEach(item => {
      days[moment(item.time).format('dddd')] += item.msg_count
    })

    this.rawData.msg_count_hour.forEach(item => {
      if (hours[moment(item.time).format('HH:mm')] !== undefined) {
        hours[moment(item.time).format('HH:mm')] += item.msg_count
      } else {
        hours[moment(item.time).format('HH:mm')] = 0
      }
    })

    this.activityMsg = {
      days: {
        series: [
          {
            name: this.$t('statistics_msg_count_msg_day_activity_title').toString(),
            data: [ ...Object.values(days) ]
          }
        ],
        labels: Object.keys(days).map(item => this.$t(`week_day_picker_${ item.toLowerCase() }`).toString())
      },
      hours: {
        series: [
          {
            name: this.$t('statistics_msg_count_msg_hour_activity_title').toString(),
            data: [ ...Object.entries(hours).sort((a, b) => Number(a[0]) - Number(b[0])).map(item => item[1]) ]
          }
        ],
        labels: Object.keys(hours)
      }
    }

  }
  // this.dayTable = [ { ...days } ]
  // this.hourTable = [ { ...hours } ]
}
