










































import { Component, Prop } from "vue-property-decorator";
import { RecordParams } from "../../../logic/types";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import Vue from 'vue'

@Component({
  components: {
    VuePerfectScrollbar,
    Icon
  }
})
export default class RecordCard extends Vue {
  @Prop() GlobalRecords!: { [key: string]: RecordParams }
}
