



















import { Statistics, User, UsersTick } from "@/views/chat/statistics/logic/types";
import isMobile from "@/assets/utils/isMobile";
import { TagRowItem } from '@/components/TagRow/types'
import TagsRow from '@/components/TagRow/TagsRow.vue'

import { UseFields } from "piramis-base-components/src/components/Pi";
import MultiChart from 'piramis-base-components/src/components/Charts/MultiChart/MultiChart.vue'
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { MultiChartData, MultiChartOptions } from "piramis-base-components/src/components/Charts/MultiChart/type";

import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { sum } from "lodash";

@Component({
  components: {
    TagsRow,
    MultiChart,
    TableExportButtons
  }
})
export default class MixedUsersStatistic extends UseFields {
  @Prop() rawData!: Statistics

  @Prop() loaded!: boolean

  @Watch('loaded')
  loadedWatcher(value: boolean): void {
    if (!value) {
      this.mixedUsers = null
    }
  }

  table: any = null

  mixedUsers: { data: MultiChartData, options: MultiChartOptions } | null = null

  getTable(sortData: Array<UsersTick>): void {
    let returnedArr: Array<{[key: string]: string | number}> = []
    sortData.forEach(dataItem => {
      let obj: {[key: string]: string | number} = {}
      obj[this.$t('statistics_time_title').toString()] = dataItem.time ? dataItem.time : '-'
      obj[this.$t('statistics_new_title').toString()] = dataItem.new ? dataItem.new : '-'
      obj[this.$t('statistics_kick_title').toString()] = dataItem.kick ? dataItem.kick : '-'
      obj[this.$t('statistics_online_title').toString()] = dataItem.online ? dataItem.online : '-'
      obj[this.$t('statistics_leave_title').toString()] = dataItem.leave ? dataItem.leave : '-'
      obj[this.$t('statistics_total_title').toString()] = dataItem.total ? dataItem.total : '-'

      returnedArr.push(obj)
    })
    this.table = returnedArr
  }

  getTickValues(model: any, key: string):Array<number | null> {
    return model.map((item:any) => item[key] ?? null)
  }

  totalValues: Array<TagRowItem> = []

  get usersNewDayTotalUsersSum() {
    return sum(this.rawData.users_new_day.map(user => user.total_users))
  }

  mounted(): void {
    const sortData: Array<UsersTick> = this.rawData.users_tick.sort((a, b) => new Date(a.time).valueOf() - new Date(b.time).valueOf())

    const prevCurrentDateTotal = sortData.find(r => r.time === moment().add(-1, 'd').format('YYYY-MM-DD 00:00:00'))?.total

    //selected period values
    const total = this.getTickValues(sortData, 'total')
    const newUsers = this.getTickValues(this.rawData.users_new_day, 'total_users')
    const online = this.getTickValues(sortData, 'online')
    const leave = this.getTickValues(sortData, 'leave')
    const kick = this.getTickValues(sortData, 'kick')

    this.totalValues = [
      {
        value: prevCurrentDateTotal ?? '-',
        title: 'statistics_users_tick_new_mixed_chart_total_title',
        color: 'rgba(var(--a-primary), 1)',
        icon: 'user'
      },
      {
        value: this.usersNewDayTotalUsersSum,
        title: 'common_chat_records_new_users',
        color: 'rgba(var(--a-success), 1)',
        icon: 'user-add'
      },
      {
        value: sum(leave),
        title: 'statistics_users_tick_leave_mixed_chart_total_title',
        color: 'rgba(var(--a-dark), 1)',
        icon: 'user-delete'
      },
      {
        value: sum(kick),
        title: 'statistics_users_tick_kicked_mixed_chart_total_title',
        color: 'rgba(var(--a-danger), 1)',
        icon: 'usergroup-delete'
      },
    ]

    this.mixedUsers = {
      data: {
        series: [
          {
            name: this.$t('statistics_new_title').toString(),
            data: newUsers,
            type: 'column'
          },
          {
            name: this.$t('statistics_leave_title').toString(),
            data: leave,
            type: 'column'
          },
          {
            name: this.$t('statistics_kick_title').toString(),
            data: kick,
            type: 'column'
          },
          {
            name: this.$t('statistics_total_title').toString(),
            data: total,
            type: 'line'
          },
          {
            name: this.$t('statistics_online_title').toString(),
            data: online,
            type: 'line'
          },
        ],
        labels: sortData.map(item => moment(item.time).format('YYYY-MM-DD 00:00:00'))
      },
      options: {
        chart: {
          height: 500,
          xAxis: {
            enabled: true
          },
          yAxis: {
            enabled: true,
            hideLine: true
          },
          sparkline: true,
        },
        zoom: sortData.length > 35,
        colors: [ 'rgba(var(--a-success), 1)', 'rgba(var(--a-dark), 1)', 'rgba(var(--a-danger), 1)', 'rgba(var(--a-primary)', 'rgba(var(--a-warning), 1)' ],
        stroke: {
          curve: 'smooth',
          width: 2
        },
        dataLabels: {
          fontSize: isMobile() ? '10px' : '8px',
          fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
          fontWeight: 400
        },
      }
    }
    this.getTable(sortData)
  }
}
