


























































import RecordCard from "./recordCard.vue";
import { RecordParams, Statistics } from "@/views/chat/statistics/logic/types";
import { ChartData } from '@/includes/logic/dashboard/types'

import PieChart from 'piramis-base-components/src/components/Charts/PieChart/PieChart.vue'
import ColumnChart from "piramis-base-components/src/components/Charts/ColumnChart/columnChart.vue";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";

import Vue from 'vue'
import { Component, Prop } from "vue-property-decorator";
import moment from "moment"
import { sum } from "lodash";

@Component({
  components: {
    RecordCard,
    ColumnChart,
    Icon,
    PieChart
  },
  data() {
    return {
      moment
    }
  }
})
export default class GlobalChartRecords extends Vue {
  @Prop({ default: null }) rawData!: Statistics

  @Prop() loaded!: boolean

  // records: { [key: string]: RecordParams } | null = null
  records: Array<{title: string, metrics: Array<RecordParams>, chart: ChartData}> = []

  columnChartOptions = {
    'chart': {
      height: 120,
      'sparkline': true,
      'xAxis': {
        'enabled': false,
      },
      'yAxis': {
        'enabled': false
      },
    },
    'zoom': false,
    'colors': [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-success), 1)', 'rgba(var(--a-danger), 1)' ],
  }

  mounted() {
    const total_users = {
      title: 'Всего',
      icon: 'users',
      color: 'var(--a-primary)',
      value: this.rawData.users_tick.find(item => item.time === moment().add(-1, 'day').format('YYYY-MM-DD 00:00:00'))!.total as number
    }

    const new_users = {
      title: 'Новых',
      icon: 'user-plus',
      color: 'var(--a-success)',
      value: sum(this.rawData.users_new_day.map(user => user.total_users))
    }

    const leave_users = {
      title: 'Вышло',
      icon: 'user-minus',
      color: 'var(--a-danger)',
      value: sum(this.rawData.users_tick.map(user => user.leave))
    }

    const avg_dau = {
      title: 'За сутки',
      icon: 'triangle',
      color: 'var(--a-primary)',
      value: Math.floor(sum(this.rawData.msg_count_day.map(item => item.users_count)) / this.rawData.msg_count_day.length)
    }

    const avg_wau = {
      title: 'За неделю',
      icon: 'triangle',
      color: 'var(--a-success)',
      value: Math.floor(sum(this.rawData.msg_count_week.map(item => item.users_count)) / this.rawData.msg_count_week.length)
    }

    const msg_count = {
      title: 'Всего',
      icon: 'send',
      color: 'var(--a-primary)',
      value: sum(this.rawData.msg_count_month.map(item => item.msg_count)),
    }

    const avg_msg_count_day = {
      title: 'В день',
      icon: 'send',
      color: 'var(--a-success)',
      value: Math.floor(sum(this.rawData.msg_count_day.map(item => item.msg_count)) / this.rawData.msg_count_day.length)
    }

    const avg_msg_count_hour = {
      title: 'В час',
      icon: 'send',
      color: 'var(--a-danger)',
      value: Math.floor(sum(this.rawData.msg_count_hour.map(msg => msg.msg_count)) / this.rawData.msg_count_hour.length)
    }

    this.records = [
      // {
      //   title: 'пользователей',
      //   metrics: [
      //     total_users,
      //     new_users,
      //     leave_users,
      //   ],
      //   chart: {
      //     'series': [
      //       {
      //         'name': 'total_users',
      //         'data': [
      //           total_users.value,
      //         ],
      //       },
      //       {
      //         'name': 'new_users',
      //         'data': [
      //           new_users.value,
      //         ],
      //       },
      //       {
      //         'name': 'leave_users',
      //         'data': [
      //           leave_users.value,
      //         ],
      //       }
      //     ],
      //     'labels': [ '', ]
      //   }
      // },
      {
        title: 'Активных пользователей',
        metrics: [
          avg_dau,
          avg_wau,
        ],
        chart: {
          'series': [
            {
              'name': '1',
              'data': [
                avg_dau.value,
              ],
            },
            {
              'name': 'avg_wau',
              'data': [
                avg_wau.value,
              ],
            }
          ],
          'labels': [ '', ]
        }
      },
      {
        title: 'Сообщений',
        metrics: [
          msg_count,
          avg_msg_count_day,
          avg_msg_count_hour,
        ],
        chart: {
          'series': [
            {
              'name': 'msg_count',
              'data': [
                msg_count.value,
              ],
            },
            {
              'name': 'avg_msg_count_day',
              'data': [
                avg_msg_count_day.value,
              ],
            }
            ,
            {
              'name': 'avg_msg_count_hour',
              'data': [
                avg_msg_count_hour.value,
              ],
            }

          ],
          'labels': [ '' ]
        }
      }
    ]
  }
}
