
























































import { Statistics } from '@/views/chat/statistics/logic/types'
import MsgActivityBars from "@/views/chat/statistics/components/mainStatistics/msgActivityBars.vue";

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { sum } from 'lodash'

@Component({
  components: {
    MsgActivityBars
  }
})
export default class MsgActivityWrapper extends Vue {
  @Prop({ default: null }) rawData!: Statistics

  get avg_msg_count_day() {
    return (
      this.rawData
      && this.rawData.msg_count_day
      && this.rawData.msg_count_day.length
      && Math.floor(sum(this.rawData.msg_count_day.map(item => item.msg_count)) / this.rawData.msg_count_day.length)
    ) || 0
  }

  get totalMessagesCountMonth() {
    return sum(this.rawData.msg_count_month.map(item => item.msg_count))
  }

  get avg_msg_count_hour() {
    return (
      this.rawData
      && this.rawData.msg_count_hour
      && this.rawData.msg_count_hour.length
      && Math.floor(sum(this.rawData.msg_count_hour.map(msg => msg.msg_count)) / this.rawData.msg_count_hour.length)
    ) || 0
  }

}
