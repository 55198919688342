export default [
  "users_tick",
  "users_new_hour",
  "users_new_day",
  "msg_count_hour",
  "msg_count_day",
  "msg_count_week",
  "msg_count_month",
  "users_retention",
  "messages_retention",
  "engagement_rate_week",
  "engagement_rate_month",
]
